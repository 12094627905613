@import '../../../assets/css/variables';

.theme-sidebar-navbar {
    .navbar-nav {
        .btn-link {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                width: 4px;
                height: 29px;
                transform: translateY(-50%);
                border-radius: 0px 5px 5px 0px;
                background-color: transparent;
                transition: all .5s ease-in-out;
            }

            &.active {
                &::before {
                    background-color: $info;
                }
            }
        }
    }
}

.theme-panel-cover {
    background-color: #1C2D3A;
    width: 0;
    transition: all .5s ease;

    @include below-xl() {
        position: absolute;
        left: -260px;
        box-shadow: $shadow-lg;
    }

    &.active {
        width: 260px;

        @include below-xl() {
            left: 50px;
        }
    }

    .accordion-header {
        .accordion-button {
            gap: .75rem;
            width: auto;
        }
    }
}