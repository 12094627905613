/* import bootstrap to set changes */
@import './assets/css/variables';
@import '~bootstrap/scss/bootstrap';


// Override css variables of react-toastify
:root {
  --toastify-color-info: #{$info};
  --toastify-color-success: #{$success};
  --toastify-color-warning: #{$warning};
  --toastify-color-error: #{$danger};
  --toastify-font-family: #{$font-family-base};
  --toastify-color-progress-light: #{$primary};
  --toastify-color-progress-dark: #{$primary};
}

.tooltip {
  position: fixed !important;
}

// Custom Scrollbar
body {
  overflow-y: scroll;
}

@include above-xl() {
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--bs-secondary);

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--bs-secondary);
      border-radius: 20px;

      &:hover {
        background-color: var(--bs-primary);
      }
    }
  }
}

//Custom CSS
.mw-450 {
  max-width: 450px;
}

.w-52 {
  width: 52px;

  @include below-md() {
    width: 40px;
  }
}

.h-52 {
  height: 52px;

  @include below-md() {
    height: 40px;
  }
}

.theme-chat-gradient {
  background: -webkit-linear-gradient(90deg, #0D1A2B 0%, #3A5057 37.01%, #2C3F46 67.2%, #9D7F66 90.16%);
  background: -moz-linear-gradient(90deg, #0D1A2B 0%, #3A5057 37.01%, #2C3F46 67.2%, #9D7F66 90.16%);
  background: -o-linear-gradient(90deg, #0D1A2B 0%, #3A5057 37.01%, #2C3F46 67.2%, #9D7F66 90.16%);
  background: linear-gradient(90deg, #0D1A2B 0%, #3A5057 37.01%, #2C3F46 67.2%, #9D7F66 90.16%);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px $secondary;
  }

  100% {
    box-shadow: 0 0 0 20px rgba($dark, 0);
  }

}

.theme-pulse {
  animation: pulse 1s infinite;
}