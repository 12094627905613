$enable-negative-margins: true;
$font-family-base: 'Outfit', sans-serif;
$black: #000000;
$white: #FFFFFF;
$body-color: $black;
$primary: #fc7f05;
$secondary: #9D7F66;
$danger: #D41A1F;
$info: #939AFF;
$dark: #122030;
$warning: #f1c40f;
$success: #198754;
$card-spacer-x: 1.5rem;
$card-spacer-y: $card-spacer-x;
$tooltip-max-width: 300px;
$shadow-lg: 0px 5px 28px 0px rgba($black, 0.90);
$link-color: $info;
$btn-link-color: $info;
$btn-link-hover-color: #7981f0;
$tooltip-bg: $dark;

$accordion-padding-y: 1rem;
$accordion-padding-x: 0;
$accordion-color: #CCCCCC;
$accordion-bg: transparent;
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-body-padding-y: 0;
$accordion-body-padding-x: .75rem;
$accordion-button-color: $info;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $info;
$accordion-button-padding-y: .5rem;
$accordion-icon-width: 1rem;
$accordion-icon-color: $info;
$accordion-icon-active-color: $info;
$accordion-button-focus-box-shadow: none;

//Responsive Mixins
$below-xsm: 374px;
$below-sm: 575px;
$below-md: 767px;
$below-lg: 991px;
$below-xl: 1199px;
$below-1300: 1299px;
$below-1400: 1399px;
$below-1600: 1600px;
$below-1800: 1800px;

$maxheight600: 600px;
$maxheight400: 400px;

$above-sm: 576px;
$above-md: 768px;
$above-lg: 992px;
$above-xl: 1200px;
$above-1300: 1300px;

@mixin below-xsm {
  @media only screen and (max-width: $below-xsm) {
    @content;
  }
}

@mixin below-sm {
  @media only screen and (max-width: $below-sm) {
    @content;
  }
}

@mixin below-md {
  @media only screen and (max-width: $below-md) {
    @content;
  }
}

@mixin below-lg {
  @media only screen and (max-width: $below-lg) {
    @content;
  }
}

@mixin below-xl {
  @media only screen and (max-width: $below-xl) {
    @content;
  }
}

@mixin below-1300 {
  @media only screen and (max-width: $below-1300) {
    @content;
  }
}

@mixin below-1400 {
  @media only screen and (max-width: $below-1400) {
    @content;
  }
}

@mixin below-1600 {
  @media only screen and (max-width: $below-1600) {
    @content;
  }
}

@mixin below-1800 {
  @media only screen and (max-width: $below-1800) {
    @content;
  }
}

@mixin maxheight600 {
  @media only screen and (max-height: $maxheight600) {
    @content;
  }
}

@mixin maxheight400 {
  @media only screen and (max-height: $maxheight400) {
    @content;
  }
}

@mixin above-sm {
  @media only screen and (min-width: $above-sm) {
    @content;
  }
}

@mixin above-md {
  @media only screen and (min-width: $above-md) {
    @content;
  }
}

@mixin above-lg {
  @media only screen and (min-width: $above-lg) {
    @content;
  }
}

@mixin above-xl {
  @media only screen and (min-width: $above-xl) {
    @content;
  }
}

@mixin above-1300 {
  @media only screen and (min-width: $above-1300) {
    @content;
  }
}

//End of Responsive Mixins